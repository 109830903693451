export const environment = {
    production: true,
    backendUrlHost: 'https://5ug4n2og89.execute-api.eu-west-1.amazonaws.com/uat/usermgt',
    companyService : {
      hostUrl : 'https://5ug4n2og89.execute-api.eu-west-1.amazonaws.com/uat/dataservice',
    },
    localStorageEncKey : 'XFNUIoykIRPROV',
    nodeAwsChime: 'https://5ug4n2og89.execute-api.eu-west-1.amazonaws.com/uat/chimeservice',
    staticResourcesURL : 'https://d19f1eb6u8r9z.cloudfront.net',
    fileSize : 1048576,
    fileSizeStr: "10 MB",
    allowedFileFormat : `image/png,image/jpeg,image/jpg,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
    application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint,
    application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword`,
  };