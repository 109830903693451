import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthenticateMeetingAttendee} from './guards/authenticateMeetingAttendee';
import {AuthGuard} from "./services/authentication/auth.guard";
import { AwsChimeMeetingComponent } from './shared-components/aws-chime-meeting/aws-chime-meeting.component';
import { PagenotfoundComponent } from './shared-components/pagenotfound/pagenotfound.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./Users/public-module/public-module.module').then(m => m.PublicModuleModule)
  },
  {
      path: 'company-user',
      loadChildren: () => import('./Users/company/company.module').then(m => m.CompanyModule),
      // canLoad: [LazyLoadAuthGuard]
      canActivate: [AuthGuard]
  },
  {
    path: 'investor',
    loadChildren: () => import('./Users/investor/investor.module').then(m => m.InvestorModule),
    // canLoad: [LazyLoadAuthGuard]
    canActivate: [AuthGuard]
},
  {
    path:'authentication',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: "join-meeting/:mid",
    component: AwsChimeMeetingComponent,
    canActivate: [AuthenticateMeetingAttendee],
    data: {isconferrence : false}
  },
  {
    path: "join-conference/:mid",
    component: AwsChimeMeetingComponent,
    canActivate: [AuthenticateMeetingAttendee],
    data: {isconferrence : true}
  },
  
  {path: '**', redirectTo: '/404', pathMatch: 'full'},
  {
    path: '404',
    component: PagenotfoundComponent
  }
  
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
