import {Pipe, PipeTransform} from '@angular/core';
import {CompanyProfile} from '../Models/company-data-models/company-profile';

@Pipe({
    name: 'companyProfileFilter'
})
export class CompanyProfileFilterPipe implements PipeTransform {
    list: CompanyProfile[] = [];

    transform(list: any[], value: any[], keys: any[]): CompanyProfile[] {

        value.forEach((searchF: string, index: number) => {

            if (searchF) {
                list = list.filter(profile => {
                    return profile[keys[index]].toString().toLowerCase().indexOf(searchF.toString().toLowerCase()) !== -1

                })
            }
        });
        return list;
    }

}
