
<router-outlet></router-outlet>
<div class="chatBot">
    <div class="box">

        <div class="boxHeader">
            <h2 class="primary-font fw-600 text-16 mb-0">{{"contactus" | translate}}</h2>
            <div class="action d-flex">
                <button class="close closeHide mright-15">
                    <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.2812 0.09375H0.21875C0.0820312 0.09375 0 0.203125 0 0.3125V1.1875C0 1.32422 0.0820312 1.40625 0.21875 1.40625H10.2812C10.3906 1.40625 10.5 1.32422 10.5 1.1875V0.3125C10.5 0.203125 10.3906 0.09375 10.2812 0.09375Z"
                              fill="white"/>
                    </svg>
                </button>
                <button class="close closeData">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.27734 4.75L9.09375 1.96094L9.66797 1.38672C9.75 1.30469 9.75 1.16797 9.66797 1.05859L9.06641 0.457031C8.95703 0.375 8.82031 0.375 8.73828 0.457031L5.375 3.84766L1.98438 0.457031C1.90234 0.375 1.76562 0.375 1.65625 0.457031L1.05469 1.05859C0.972656 1.16797 0.972656 1.30469 1.05469 1.38672L4.44531 4.75L1.05469 8.14062C0.972656 8.22266 0.972656 8.35938 1.05469 8.46875L1.65625 9.07031C1.76562 9.15234 1.90234 9.15234 1.98438 9.07031L5.375 5.67969L8.16406 8.49609L8.73828 9.07031C8.82031 9.15234 8.95703 9.15234 9.06641 9.07031L9.66797 8.46875C9.75 8.35938 9.75 8.22266 9.66797 8.14062L6.27734 4.75Z"
                              fill="white"/>
                    </svg>
                </button>
            </div>
        </div>
        <div id="chat" class="boxChat">
            <div id="messages" class="messages"></div>
            <div class="startChat">
                <form>
                    <div class="row">
                        <!-- <div class="col-12">  
                            <div class="form-group mb-0">
            
                            <input  type="text" 
                                    class="form-control primary-font text-16"
                                    name="name"
                                    id="name"
                                    placeholder="Enter your first name"
                                    value="" >
                            
                            </div>
                        </div>
                        <div class="col-12">
                            
                        <div class="form-group mb-0">
                            
                            <textarea class="form-control  primary-font text-16"
                                    name="message"
                                    id="message"
                                    placeholder="Message*"
                                    rows="3"
                                    ></textarea>

                            
                        </div>

                        </div> -->
                        <!-- <div class="col-12">
                            
                        <button id=""
                                class="btn d-flex mx-auto startChatButton capitalize primary-font fw-500 text-16"
                                type="button" >
                            
                            Send message
                        </button>

                        </div> -->
                    </div>
                </form>
            </div>
        </div>
        <div class="boxFooter">
            <div class="options">
            </div>
            <form id="formChat">
                <input id="input" type="text" placeholder='{{"write_your_ms" | translate}}' autocomplete="off"/>
                <button class="send">
                    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.0312 0.941406L1.65625 7.50391C0.699219 8.05078 0.78125 9.44531 1.79297 9.85547L4.9375 11.1406V13.4375C4.9375 14.7227 6.55078 15.2422 7.28906 14.2305L8.49219 12.6172L11.5547 13.875C11.7188 13.957 11.8828 13.9844 12.0469 13.9844C12.2656 13.9844 12.4844 13.9297 12.7031 13.8203C13.0586 13.6016 13.2773 13.2734 13.3594 12.8633L14.9727 2.28125C15.1367 1.1875 13.9609 0.394531 13.0312 0.941406ZM6.25 13.4375V11.6875L7.23438 12.0977L6.25 13.4375ZM12.0469 12.6719L7.83594 10.9219L11.6914 5.39844C11.9648 4.96094 11.418 4.46875 11.0352 4.82422L5.23828 9.85547L2.3125 8.625L13.6875 2.0625L12.0469 12.6719Z"
                              fill="white"/>
                    </svg>
                </button>
            </form>
        </div>
    </div>
    <button class="openChatBot">
        <img src="assets/images/icons/chat.png" alt="chat">
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.1758 8.25L15.4062 3.07031L16.4727 2.00391C16.625 1.85156 16.625 1.59766 16.4727 1.39453L15.3555 0.277344C15.1523 0.125 14.8984 0.125 14.7461 0.277344L8.5 6.57422L2.20312 0.277344C2.05078 0.125 1.79688 0.125 1.59375 0.277344L0.476562 1.39453C0.324219 1.59766 0.324219 1.85156 0.476562 2.00391L6.77344 8.25L0.476562 14.5469C0.324219 14.6992 0.324219 14.9531 0.476562 15.1562L1.59375 16.2734C1.79688 16.4258 2.05078 16.4258 2.20312 16.2734L8.5 9.97656L13.6797 15.207L14.7461 16.2734C14.8984 16.4258 15.1523 16.4258 15.3555 16.2734L16.4727 15.1562C16.625 14.9531 16.625 14.6992 16.4727 14.5469L10.1758 8.25Z"
                  fill="white"/>
        </svg>
    </button>
</div>
   