import {constants} from '../Utils/App-Constants';
import {environment} from '../../environments/environment';
import {LanguageHandlerService} from '../services/language-handler.service';

export class URLGenerator {
    //context = window.location.protocol + '//' + window.location.host;
    private baseUrlNode: string = '';
    private baseUrlJava: string = '';
    private baseAwsChime: string = '';
    private staticResourceURL: string = '';
    private context = constants.searchConstants.hostName;
    //supportedExchanges = 'ADSM,AMEX,ASE,BSE,CASE,CSE,DFM,DIFX,DJI,DSM,ISE,ISX,KSE,LBBSE,LSE,LSM,MSM,NSDQ,NYSE,PSE,SYDSE,TDWL,TNTSE,FIGS,DFNMF,PFX,GTISEP,GTISPM,DFNMM,FTSE,S&P,SDKSE,ITSE';

    constructor() {
        
        this.baseUrlJava = environment.backendUrlHost;
        this.baseUrlNode = environment.companyService.hostUrl;
        this.staticResourceURL = environment.staticResourcesURL;
        this.baseAwsChime = environment.nodeAwsChime;
    }

    createMeeting(){
        return this.baseAwsChime.concat(constants.AwsChime.createMeeting);
    }

    getMeeting(mid: string){
        return this.baseAwsChime.concat(constants.AwsChime.getMeeting,'?m=', mid);
    }

    createAttendee(mid: string, uid: string){
        return this.baseAwsChime.concat(constants.AwsChime.createAttendee,'?m=', mid,'&u=', uid);
    }

    getCompanySearchURL(searchKey: string, lang: string) {

        return this.baseUrlNode.concat(constants.companyService.searchCompanyApiUrl, '?pgs=', '10', `&cname=${searchKey}` , `&smname=${searchKey}`, '&lang=', lang);
    }

    getSignUpURL() {
        return this.baseUrlJava.concat(constants.backendConstants.authenticationConstants.controller,
            constants.backendConstants.authenticationConstants.signUpMethod);
    }

    getSignInURL() {
        return this.baseUrlJava.concat(constants.backendConstants.authenticationConstants.controller,
            constants.backendConstants.authenticationConstants.signinMethod);
    }

    getForgetPasswordURL() {
        return this.baseUrlJava.concat(constants.backendConstants.authenticationConstants.controller,
            constants.backendConstants.authenticationConstants.forgetPassword);
    }

    getResetPassword(token: string) {
        return this.baseUrlJava.concat(constants.backendConstants.authenticationConstants.controller,
            constants.backendConstants.authenticationConstants.resetPassword,
            "?token=", token);
    }

    getEmailConfirmation(token: string) {
        return this.baseUrlJava.concat(constants.backendConstants.authenticationConstants.controller,
            constants.backendConstants.authenticationConstants.confirmAccount,
            "?token=", token);
    }

    getCompanyProfileData(pgs: number) {

        return this.baseUrlNode.concat(constants.companyService.getCompaniesApiUrl, '?pgs=', pgs.toString());
    }

    getCompanyTopStockData(topStock: boolean) {

        return this.baseUrlNode.concat(constants.companyService.getCompaniesApiUrl,'?topstock=',topStock.toString());
    }

    searchCPURL(pgs: number, query: string) {

        return this.baseUrlNode.concat(constants.companyService.searchCompanyApiUrl, '?pgs=', pgs.toString(), query);
    }

    searchCEvents(query: string){
        return this.baseUrlNode.concat(constants.investorService.getEvents, query);
    }

    getCompanySectors() {
        return this.baseUrlNode.concat(constants.companyService.companySectors);
    }

    getCompanyExchanges() {
        return this.baseUrlNode.concat(constants.companyService.companyExchange);
    }

    getCompanyManagement(companyID: string) {
        return this.baseUrlNode.concat(constants.companyService.cpManagement, '?id=', companyID);
    }

    getCompanyAuditors(companyID: string) {
        return this.baseUrlNode.concat(constants.companyService.companyAuditors, '?id=', companyID);
    }

    getCompanyListingStatus() {
        return this.baseUrlNode.concat(constants.companyService.companyListingStatus);
    }

    getCompanyInvs(compId: string) {
        return this.baseUrlNode.concat(constants.companyService.companyInvs, "?pgs=1&id=", compId);
    }

    getCompanyIcon(companyId: string) {
        return this.staticResourceURL.concat(constants.companyIcon, companyId, '.png')
    }

    getNewsAnnoucementsImg(companyId: string) {
        return this.staticResourceURL.concat(constants.newsAnnouncementsImg,'newsImages', companyId, '.png')
    }

    getIRPORNewsAnnoucementsImg(imgURL: string) {
        return this.staticResourceURL.concat(constants.newsAnnouncementsImg, imgURL)
    }

    getCompMemImg(memId: string) {
        return this.staticResourceURL.concat(constants.companyService.membersPicUrls, memId, '.png')
    }

    getCountryFlag(countryCode: string) {

        return this.staticResourceURL.concat(constants.companyCountryFlag, countryCode.toLocaleLowerCase(), '.svg')
    }

    getInvSubReqURL() {
        return this.baseUrlNode.concat(constants.investorService.subscription);
    }

    getCheckInvSubReqURL() {
        return this.baseUrlNode.concat(constants.investorService.checkSubs);
    }

    initiateRequest() {
        return this.baseUrlNode.concat(constants.investorService.initiateRequest);
    }

    unsubInvSubReqURL() {
        return this.baseUrlNode.concat(constants.investorService.unsubCompany);
    }

    getSubCompanies(invsid: any, pgs: number) {
        return this.baseUrlNode.concat(constants.investorService.getSubCompanies, '?id=', invsid, '&pgs=', pgs.toString());
    }

    getInvProfile(userID: any) {
        return this.baseUrlJava.concat(constants.investorService.getProfile, userID)
    }

    getCountries() {
        return this.baseUrlNode.concat(constants.countryList)
    }

    getCities(countryId: any) {
        return this.baseUrlNode.concat(constants.citiesList, countryId)
    }

    updateInvestorProfile() {
        return this.baseUrlJava.concat(constants.investorService.editProfile)
    }

    getCountryByCode(countryCode: any) {
        return this.baseUrlNode.concat(constants.getCountryByCode, "?code=", countryCode);
    }

     getCityByCode(citycode: any){
         return this.baseUrlNode.concat(constants.getCityByCode,'?code=',citycode);
     }
     
     getSubscribedCompanyNewAnn(invsid: any, pgs: any, lang: any){
      return this.baseUrlNode.concat(constants.investorService.getNewsAnnoucements,'?id=',invsid,'&pgs=', pgs, '&lan=', lang);
     }

     getCompanyNewAnn(pgs: any, lang: any, exg: string, sym: string){
        return this.baseUrlNode.concat(constants.companyService.getNewsAnnoucements,'?pgs=', pgs, '&lan=', lang,'&exg=',exg,'&sym=',sym);
     }

     createCompanyInfo(type: number){
        return this.baseUrlNode.concat(constants.companyService.createIrProNewsAnn,'?infotype=', type.toString());
     }

     updateCompanyInfo(type: number, id: string){
        return this.baseUrlNode.concat(constants.companyService.updateIrProNewsAnn,'?infotype=', type.toString(), '&id=', id);
     }

     deleteCompanyInfo(id: string, type: string){
        return this.baseUrlNode.concat(constants.companyService.deleteIrProNewsAnn,'?id=', id, '&infotype=', type);
     }

     getIRPROCompanyNewAnn(pgs: any, lang: any, sym: string){
        return this.baseUrlNode.concat(constants.companyService.getIrProNewsAnnoucements,'?pgs=', pgs, '&lan=', lang,'&sym=',sym);
     }

     getInfoDetails(newannid: any , lang : any){
        return this.baseUrlNode.concat(constants.getInfoDetails,'?id=',newannid, '&lang=',lang)
     }
    getMyRequestAgainstId(userId: any, pgs: any) {
        return this.baseUrlNode.concat(constants.investorService.getMyRequest, "?id=", userId, "&pg=", pgs);
    }

    validateMeetingAttendee(mid: number, uid : number){
        return this.baseUrlNode.concat(constants.validateMeetingAttendees,'?mid=',mid.toString(),'&uid=',uid.toString());

    }

    getOtherRequestAgainstId(userId: any, pgs: any) {
        return this.baseUrlNode.concat(constants.investorService.getOtherRequest, "?id=", userId, "&pg=", pgs);
    }

    getSubscribedNewAnnSearch(data: any, lan: any, pgs: any){
        return this.baseUrlNode.concat(constants.investorService.getNewsAnnoucementsSearch,
            '?companyName=',data.companyName,'&exchange_code=', data.exchange_code,
            '&fromDate=',data.fromDate,'&toDate=', data.toDate ,'&lan=',lan, '&pgs=', pgs);
    }

    editRequest() {
        return this.baseUrlNode.concat(constants.investorService.editRequest);
    }

    responsedRequest() {
        return this.baseUrlNode.concat(constants.investorService.responedRequest);
    }

    deleteRequest(request_id: any) {
        return this.baseUrlNode.concat(constants.investorService.deleteRequest, "?id=", request_id);
    }


    deleteDocumentReq(){
        return this.baseUrlNode.concat(constants.investorService.removeDoc);
    }

    downloadReqDocumentReq(docKey: string){
        return this.baseUrlNode.concat(constants.downloadDoc,"?key=", docKey);
    }

    searchRequest(searchReq: any) {
        return this.baseUrlNode.concat(constants.investorService.filterRequest,
            "?st=", searchReq.st,"&sd=",searchReq.sd,"&ed=",searchReq.ed,"&pg=",searchReq.pg,"&rt=",searchReq.rt,"&uid=",searchReq.uid);
    }

    getCompanyEvents(id: any, pgs: any){
        return this.baseUrlNode.concat(constants.investorService.getEvents,'?id=',id, '&pgs=',pgs);
    }

    getAllInvestor(pageNumber: number, pageSize: number) {
        return this.baseUrlJava.concat(constants.companyService.getAllInvestor, '?pgs=', pageSize.toString(), '&pgn=', pageNumber.toString());
    }

    searchInvestor(value: any) {
        return this.baseUrlJava.concat(constants.companyService.searchInvestor,
            '?investorName=', value.investorName , value?.country ? ('&country=' + value?.country) : '');
    }

    getSubInvestors(id: any) {
        return this.baseUrlNode.concat(constants.companyService.getSubscribedInvestors,
            '?companyId=', id);
    }

    getSubInvestorsDto(id: any, pgn: any, pgs: any) {
        return this.baseUrlJava.concat(constants.companyService.getSubscribedInvestorsDto,
            '?values=', id, '&pgn=', pgn, '&pgs=', pgs);
    }

    getCompanyWidgets(companyId: any, lan: any) {
        return this.baseUrlNode.concat(constants.companyService.getCompanyWidgets,
            '?companyId=', companyId, '&lan=', lan);
    }
    contactUs() {
        return this.baseUrlJava.concat(constants.contactUs);
    }
    createVirtualConference() {
        return this.baseUrlNode.concat(constants.createVirtualConference);
    }

    updateVirtualConference() {
        return this.baseUrlNode.concat(constants.updateVirtualConference);
    }
    getVirtualConference() {
        return this.baseUrlNode.concat(constants.getVirtualConference);
    }
    deleteVirtualConference() {
        return this.baseUrlNode.concat(constants.deleteVirtualConference);
    }
}