<div id="flow-fatal" class="flow">
  <div style="vertical-align: middle; text-align: left; width: 40%; margin: 10em 30% 10em 30%;">
    <h1>Fatal error</h1>
    <p>An error was thrown that should not occur in ordinary use. This should be considered a fatal error for testing
      and canary purposes.</p>
    <p>This error will ordinarily be muffled, but you should consider removing this code if you are building an app
      based on this demo.</p>
    <code id="stack"></code>
  </div>
</div>

<!-- Initial meeting authentication screen with meeting and name inputs -->
<div id="flow-authenticate" class="flow text-center p-2">
  <div class="text-muted" style="position:fixed;right:3px;bottom:3px" id="sdk-version"></div>
  <div class="container">
    <form id="form-authenticate">
      <h1 class="h3 mb-3 fw-normal">Join a meeting</h1>
      <div class="form-floating row mt-3">
        <input type="name" id="inputMeeting" class="form-control" placeholder="Meeting Title or ID" required autofocus>
        <label for="inputMeeting" style="text-align: left;">Meeting Title or ID</label>
      </div>
      <div class="form-floating row mt-3">
        <input type="name" id="inputName" class="form-control" placeholder="Your Name" required>
        <label for="inputName" style="text-align: left;">Your Name</label>
      </div>
      <div class="form-floating row mt-3" title="Used only for meeting creation. Ignored if meeting already exists">
        <select id="inputRegion" class="form-select" style="width:100%">
          <option value="us-east-1" selected>United States (N. Virginia)</option>
          <option value="ap-northeast-1">Japan (Tokyo)</option>
          <option value="ap-southeast-1">Singapore</option>
          <option value="af-south-1">South Africa (Cape Town)</option>
          <option value="eu-south-1">Italy (Milan)</option>
          <option value="ap-south-1">India (Mumbai)</option>
          <option value="ap-northeast-2">South Korea (Seoul)</option>
          <option value="ap-southeast-2">Australia (Sydney)</option>
          <option value="ca-central-1">Canada</option>
          <option value="eu-central-1">Germany (Frankfurt)</option>
          <option value="eu-north-1">Sweden (Stockholm)</option>
          <option value="eu-west-1">Ireland</option>
          <option value="eu-west-2">United Kingdom (London)</option>
          <option value="eu-west-3">France (Paris)</option>
          <option value="sa-east-1">Brazil (São Paulo)</option>
          <option value="us-east-2">United States (Ohio)</option>
          <option value="us-west-1">United States (N. California)</option>
          <option value="us-west-2">United States (Oregon)</option>
          <option value="us-gov-west-1">GovCloud (US-West)</option>
          <option value="us-gov-east-1">GovCloud (US-East)</option>
        </select>
        <label for="inputRegion" style="text-align: left;"> Media Region</label>
      </div>
      <div class="row mt-3">
        <button type="button" class="btn btn-outline-secondary h-50 d-sm-block" data-bs-toggle="modal" id="additional-options-button"
                data-bs-target="#additional-options-modal">
          Additional Options
        </button>
      </div>
      <div class="row mt-3">
        <div class="btn-group p-0" role="group">
          <button id="authenticate" class="btn btn-lg btn-secondary w-100" type="submit">Continue</button>
          <button id="quick-join" class="btn btn-lg btn-success" title="Skip Device Selection">
            <!-- <%= require('../../node_modules/open-iconic/svg/bolt.svg') %> -->
            <img src="assets/images/svg/bolt.svg" />
          </button>
        </div>
      </div>
      <div class="row mt-3">
        <p>Anyone with access to the meeting link can join.</p>
      </div>
      <div class="row mt-3">
        <a id="to-sip-flow" href="#">Joining via SIP? Click here.</a>
      </div>
      <div class="row mt-3">
        <div id="progress-authenticate" class="w-100 progress progress-hidden">
          <div class="w-100 progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
               aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Additional options modal -->

<div class="modal fade" id="additional-options-modal" tabindex="-1"
     aria-labelledby="additional-options-modal-label" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="additional-options-modal-label">Additional Options</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <fieldset>
          <div class="form-floating mb-3" style="text-align: left;">
            <select name="logLevel" class="form-select" id="logLevelSelect">
              <option value="debug">Debug</option>
              <option value="info">Info</option>
              <option value="warn">Warn</option>
              <option value="error">Error</option>
              <option value="off">Off</option>
            </select>
            <label for="logLevel" style="text-align: left;">Log Level:</label>
          </div>
          <div class="form-floating mb-3">
            <select name="videoCodec" class="form-select" id="videoCodecSelect">
              <option value="default">Meeting Default</option>
              <option value="vp8">VP8</option>
              <option value="h264ConstrainedBaselineProfile">H.264 Constrained Baseline Profile</option>
            </select>
            <label for="videoCodec"  style="text-align: left;">Preferred Video Send Codec:</label>
          </div>
          <div class="form-check" style="text-align: left;">
            <input type="checkbox" id="webaudio" class="form-check-input">
            <label for="webaudio" class="form-check-label">Use WebAudio</label>
          </div>
          <div class="form-check" id='echo-reduction-checkbox' style="text-align: left; display: none;">
            <input type="checkbox" id="echo-reduction-capability" class="form-check-input">
            <label for="echo-reduction-capability" class="form-check-label">Use Echo Reduction (new meetings
              only)</label>
          </div>
          <div class="form-check" style="text-align: left;">
            <input type="checkbox" id="fullband-speech-mono-quality" class="form-check-input">
            <label for="fullband-speech-mono-quality" class="form-check-label">Set fullband speech (mono)
              quality</label>
          </div>
          <div class="form-check" style="text-align: left;">
            <input type="checkbox" id="fullband-music-mono-quality" class="form-check-input">
            <label for="fullband-music-mono-quality" class="form-check-label">Set fullband music (mono)
              quality</label>
          </div>
          <div class="form-check" style="text-align: left;">
            <input type="checkbox" id="fullband-music-stereo-quality" class="form-check-input">
            <label for="fullband-music-stereo-quality" class="form-check-label">Set fullband music (stereo)
              quality</label>
          </div>
          <div class="form-check" style="text-align: left;">
            <input type="checkbox" id="simulcast" class="form-check-input">
            <label for="simulcast" class="form-check-label">Enable Simulcast for Chrome</label>
          </div>
          <div class="form-check" style="text-align: left;">
            <input type="checkbox" id="priority-downlink-policy" class="form-check-input">
            <label for="priority-downlink-policy" class="form-check-label">Use Priority-Based Downlink
              Policy</label>
          </div>
          <label id="priority-downlink-policy-preset-title" for="priority-downlink-policy-preset" style="display: none; padding-top:5px">Policy Preset</label>
          <select id="priority-downlink-policy-preset" class="form-select" style="width:100%; display: none;">
            <option value="default" selected>Default</option>
            <option value="stable">Stable Network</option>
            <option value="unstable">Unstable Network</option>
          </select>
          <label id="server-side-network-adaption-title" for="server-side-network-adaption" style="display: none; padding-top:5px">Server Side Network Adaption</label>
          <select id="server-side-network-adaption" class="form-select" style="width:100%; display: none;">
            <option value="default" selected>Default</option>
            <option value="none">None</option>
            <option value="enable-bandwidth-probing">Enable Bandwidth Probing</option>
          </select>
          <label id="pagination-title" for="pagination-page-size" style="display: none; padding-top:5px">Paginate displayed video tiles</label>
          <select id="pagination-page-size" class="form-select" style="width:100%; display: none;">
            <option value="2">2</option>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25" selected>2</option>
          </select>      
          <div class="form-check" style="text-align: left;">
            <input type="checkbox" checked id="preconnect" class="form-check-input">
            <label for="preconnect" class="form-check-label">Open signaling connection early</label>
          </div>
          <div class="form-check" style="text-align: left;">
            <input type="checkbox" id="join-view-only" class="form-check-input">
            <label for="join-view-only" class="form-check-label">Join with view-only mode</label>
          </div>
          <div class="form-check" style="text-align: left;">
            <input type="checkbox" id="pause-last-frame" class="form-check-input">
            <label for="pause-last-frame" class="form-check-label">Keep Video Last Frame When Paused</label>
          </div>
          <div class="form-check" style="text-align: left;">
            <input type="checkbox" id="event-reporting" class="form-check-input">
            <label for="event-reporting" class="form-check-label">Enable event reporting</label>
          </div>
          <div class="form-check" style="text-align: left;">
            <input type="checkbox" id="replica-meeting-input" class="form-check-input">
            <label for="replica-meeting-input" class="form-check-label">Create meeting as replica</label>
            <input type="name" id="primary-meeting-external-id" class="form-control"
                   placeholder="Primary Meeting Title or ID">
          </div>
          <div class="form-check" style="text-align: left;">
            <input type="checkbox" id="delete-attendee" class="form-check-input">
            <label for="delete-attendee" class="form-check-label">Delete own attendee to leave</label>
          </div>
          <div class="form-check" style="text-align: left;">
            <input type="checkbox" id="die" class="form-check-input" checked=true>
            <label for="die" class="form-check-label">Abort on fatal errors</label>
          </div>
        </fieldset>
      </div>
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal" id="additional-options-save-button">Save</button>
    </div>
  </div>
</div>

<!-- Authenticate for SIP with meeting and voice connector ID -->

<div id="flow-sip-authenticate" class="flow text-center">
  <div class="container">
    <form id="form-sip-authenticate">
      <h1 class="h3 mb-3 font-weight-normal">Join a meeting via SIP</h1>
      <div class="row mt-3">
        <label for="sip-inputMeeting" class="visually-hidden">Meeting Title</label>
        <input type="name" id="sip-inputMeeting" class="form-control" placeholder="Meeting Title" required autofocus>
      </div>
      <div class="row mt-3">
        <label for="voiceConnectorId" class="visually-hidden">Voice Connector ID</label>
        <input type="name" id="voiceConnectorId" class="form-control" placeholder="Voice Connector ID" required>
      </div>
      <div class="row mt-3">
        <button id="button-sip-authenticate" class="btn btn-lg btn-primary" type="submit">Continue</button>
      </div>
      <div class="row mt-3">
        <p>You will need a SIP client in order to join the meeting.</p>
      </div>
    </form>
  </div>
</div>

<!-- Failure card if meeting is invalid -->

<div id="flow-failed-meeting" class="flow">
  <div class="container dir-ar">
    <form id="form-failed-meeting">
      <div class="card border-warning mb-3" style="max-width: 20rem;">
        <div id="failed-meeting" class="card-header"></div>
        <div class="card-body">
          <h4 class="card-title">{{"unable_find_meeting" | translate}}</h4>
          <p class="card-text">{{"unable_meeting_message" | translate}}</p>
          <small id="failed-meeting-error" class="text-muted"></small>
        </div>
      </div>
      <div class="d-grid gap-2">
        <button class="btn btn-lg btn-outline-warning" type="submit" (click)="back()">{{"ok" | translate}}</button>
      </div>
    </form>
  </div>
</div>

<!-- Prompt for permission to get access to device labels -->

<div id="flow-need-permission" class="flow">
  <div class="container">
    <form id="form-need-permission">
      <div class="card border-info mb-3" style="max-width: 20rem;">
        <div class="card-header">Permissions check</div>
        <div class="card-body">
          <h4 class="card-title">Unable to get device labels</h4>
          <p class="card-text">In order to select media devices, we need to do a quick permissions check of your mic
            and camera. When the pop-up appears, choose <b>Allow</b>.</p>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Display SIP URI -->

<div id="flow-sip-uri" class="flow">
  <div class="container">
    <form id="sip-uri-form">
      <div class="card border-info mb-3" style="max-width: 20rem;">
        <div class="card-header">SIP URI</div>
        <div class="card-body">
          <a id="copy-sip-uri" class="card-title" href="#">Copy</a>
          <input id="sip-uri" class="card-text" />
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Device management and preview screen -->

<div id="flow-devices" class="flow">
  <div class="container">
    <form id="form-devices">
      <h1 class="h3 mb-3 font-weight-normal text-center">Select devices</h1>
      <div class="row mt-3">
        <div class="col-12 text-center d-sm-block video-preview">
          <video id="video-preview" class="" style="border-radius:8px"></video>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <label for="audio-input block">Microphone</label>
          <select id="audio-input" class="form-select" style="width:100%"></select>
        </div>

        <div class="text-center col-12 d-sm-block">
          <label>Preview</label>
          <div class="w-100 progress" style="margin-top:0.75rem">
            <div id="audio-preview" class="progress-bar bg-success" role="progressbar" aria-valuenow="0"
                 aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div id="voice-focus-setting" class="col-12 hidden">
          <input autocomplete="off" type="checkbox" id="add-voice-focus" />
          <label style="margin-left: 0.5em" for="voice-focus-setting block">Add Voice Focus</label>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <label for="video-input block">Camera</label>
          <select id="video-input" class="form-select" style="width:100%"></select>
        </div>

      </div>
      <div class="row mt-3">
        <div class="col-12">
          <label for="video-input-quality block">Resolution</label>
          <select id="video-input-quality" class="form-select" style="width:100%">
            <option value="360p">360p (nHD) @ 15 fps (600 Kbps max)</option>
            <option value="540p" selected>540p (qHD) @ 15 fps (1.4 Mbps max)</option>
            <option value="720p">720p (HD) @ 15 fps (1.4 Mbps max)</option>
          </select>
        </div>
      </div>
      <div class="row mt-3" id="video-input-filter-container">
        <div class="col-12">
          <label for="video-input-filter block">Video Filter</label>
          <select id="video-input-filter" class="form-select" style="width:100%">
            <option value="None" selected>None</option>
          </select>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <label for="audio-output block">Speaker</label>
          <select id="audio-output" class="form-select" style="width:100%"></select>
        </div>
        <div class="col-12">
          <button id="button-test-sound" class="btn btn-outline-secondary h-50 d-sm-block"
                  style="width:100%;margin-top:2rem">Test Speaker</button>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-lg">
          <button id="joinButton" class="btn btn-lg btn-primary" style="width:100%"
                  type="submit">Join</button>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg">
          <p>Ready to join meeting <b><span id="info-meeting"></span></b> as <b><span id="info-name"></span></b>.</p>
        </div>
      </div>
    </form>
    <div id="progress-join" class="w-100 progress progress-hidden">
      <div class="w-100 progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100"
           aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  </div>
</div>

<!-- In-meeting screen -->

<div id="flow-meeting" class="flow" style="position:absolute;left:0;top:0;bottom:55px;right:0">
  <div id="live-transcription-modal" class="modal">
    <div id="live-transcription-modal-content" class="modal-content">
      <div class="modal-content-header">
        <span></span>
        <span class="modal-title text-muted">Live Transcription Configuration</span>
        <span id="button-live-transcription-modal-close" class="close">&times;</span>
      </div>
      <div id="engine-option-group active">
        <input type="radio" id="engine-transcribe" name="transcription-engine" value="engine-transcribe" checked=true>
        <label for="engine-transcribe">Amazon Transcribe</label><br>
        <input type="radio" id="engine-transcribe-medical" name="transcription-engine"
               value="engine-transcribe-medical">
        <label for="engine-transcribe-medical">Amazon Transcribe Medical</label><br>
      </div>
      <div id="engine-language-group">
        <div id="engine-transcribe-language">
          <label for="transcribe-language" class="visually-hidden">Language</label>
          <select id="transcribe-language" class="form-select" style="width:100%">
            <option value="en-US" selected>US English (en-US)</option>
            <option value="en-GB">British English (en-GB)</option>
            <option value="en-AU">Australian English (en-AU)</option>
            <option value="es-US">US Spanish (es-US)</option>
            <option value="fr-CA">Canadian French (fr-CA)</option>
            <option value="fr-FR">French (fr-FR)</option>
            <option value="it-IT">Italian (it-IT)</option>
            <option value="de-DE">German (de-DE)</option>
            <option value="pt-BR">Brazilian Portuguese (pt-BR)</option>
            <option value="ja-JP">Japanese (ja-JP)</option>
            <option value="ko-KR">Korean (ko-KR)</option>
            <option value="zh-CN">Mandarin Chinese - Mainland (zh-CN)</option>
          </select>
        </div>
        <div id="engine-transcribe-medical-language" class="hidden">
          <label for="transcribe-medical-language" class="visually-hidden">Language</label>
          <select id="transcribe-medical-language" class="form-select" style="width:100%">
            <option value="en-US" selected>US English (en-US)</option>
          </select>
        </div>
      </div>
      <div id="transcription-region-group">
        <div id="engine-transcribe-region">
          <label for="transcribe-region" class="visually-hidden">Region</label>
          <select id="transcribe-region" class="form-select" style="width:100%">
            <option value="auto" selected>Auto</option>
            <option value="">Not specified</option>
            <option value="ap-northeast-1">Japan (Tokyo)</option>
            <option value="ap-northeast-2">South Korea (Seoul)</option>
            <option value="ap-southeast-2">Australia (Sydney)</option>
            <option value="ca-central-1">Canada</option>
            <option value="eu-central-1">Germany (Frankfurt)</option>
            <option value="eu-west-1">Ireland</option>
            <option value="eu-west-2">United Kingdom (London)</option>
            <option value="sa-east-1">Brazil (São Paulo)</option>
            <option value="us-east-1">United States (N. Virginia)</option>
            <option value="us-east-2">United States (Ohio)</option>
            <option value="us-west-2">United States (Oregon)</option>
            <option value="us-gov-west-1">GovCloud (US-West)</option>
          </select>
        </div>
        <div id="engine-transcribe-medical-region" class="hidden">
          <label for="transcribe-medical-region" class="visually-hidden">Language</label>
          <select id="transcribe-medical-region" class="form-select" style="width:100%">
            <option value="auto" selected>Auto</option>
            <option value="">Not specified</option>
            <option value="ap-southeast-2">Australia (Sydney)</option>
            <option value="ca-central-1">Canada</option>
            <option value="eu-west-1">Ireland</option>
            <option value="us-east-1">United States (N. Virginia)</option>
            <option value="us-east-2">United States (Ohio)</option>
            <option value="us-west-2">United States (Oregon)</option>
          </select>
        </div>
      </div>
      <div id="engine-transcribe-language-identification">
        <div id="engine-transcribe-identify-language" class="form-check" style="text-align: left;">
          <input type="checkbox" id="identify-language-checkbox" class="form-check-input">
          <label for="identify-language-checkbox" class="form-check-label"
                 title="Automatically identify the language spoken in a media file without you having to specify a language code">Enable
            Automatic Language Identification</label>
        </div>
        <div id="language-options" class="hidden">
          <label for="language-options" class="visually-hidden">Language Options</label>
          <p
                  title="For better accuracy of automatic language identification, select at least 2 languages, one from each group.">
            <small>Select a minimum of 2 language options</small>
          </p>
          <select name="language-options" id="language-options" multiple="multiple" class="form-select"
                  style="width:100%">
            <optgroup id="group1" label="English">
              <option value="en-US">US English (en-US)</option>
              <option value="en-GB">British English (en-GB)</option>
              <option value="en-AU">Australian English (en-AU)</option>
            </optgroup>
            <optgroup id="group2" label="Spanish">
              <option value="es-US">US Spanish (es-US)</option>
            </optgroup>
            <optgroup id="group3" label="French">
              <option value="fr-FR">French (fr-FR)</option>
              <option value="fr-CA">Canadian French (fr-CA)</option>
            </optgroup>
            <optgroup id="group4" label="German">
              <option value="de-DE">German (de-DE)</option>
            </optgroup>
            <optgroup id="group5" label="Chinese">
              <option value="zh-CN">Mandarin Chinese - Mainland (zh-CN)</option>
            </optgroup>
            <optgroup id="group6" label="Italian">
              <option value="it-IT">Italian (it-IT)</option>
            </optgroup>
            <optgroup id="group7" label="Portuguese">
              <option value="pt-BR">Brazilian Portuguese (pt-BR)</option>
            </optgroup>
            <optgroup id="group8" label="Japanese">
              <option value="ja-JP">Japanese (ja-JP)</option>
            </optgroup>
            <optgroup id="group9" label="Korean">
              <option value="ko-KR">Korean (ko-KR)</option>
            </optgroup>
          </select>
          <div id="language-options-error-message"></div>
        </div>
        <div id="preferred-language" class="hidden" style="margin-top: 10px">
          <label for="preferred-language-selection" class="visually-hidden">Preferred Language</label>
          <p title="Help Amazon Transcribe identify the language in the first few seconds of your stream.">
            <small>Specify one preferred language</small>
          </p>
          <select id="preferred-language-selection" class="form-select" style="width:100%">
            <option value="" selected> -- Optional -- </option>
          </select>
        </div>
      </div>
      <div id="engine-transcribe-custom-language-model">
        <div id="engine-transcribe-language-model" class="form-check" style="text-align: left;">
          <input type="checkbox" id="custom-language-model-checkbox" class="form-check-input">
          <label for="custom-language-model-checkbox" class="form-check-label">Enable Custom Language Model</label>
        </div>
        <div id="language-model" class="hidden">
          <input type="text" id="language-model-input-text" style="text-align: left; width:100%">
        </div>
      </div>
      <div id="engine-transcribe-stability">
        <div id="engine-transcribe-partial-stabilization" class="form-check" style="text-align: left;">
          <input type="checkbox" id="partial-stabilization-checkbox" class="form-check-input">
          <label for="partial-stabilization-checkbox" class="form-check-label">Enable Partial Results
            Stabilization</label>
        </div>
        <div id="transcribe-partial-stability" class="hidden">
          <label for="partial-stability" class="visually-hidden">Transcribe Entities</label>
          <select id="partial-stability" class="form-select" style="width:100%">
            <option value="" selected> -- DEFAULT (HIGH) -- </option>
            <option value="low">LOW</option>
            <option value="medium">MEDIUM</option>
            <option value="high">HIGH</option>
          </select>
        </div>
      </div>
      <div id="engine-transcribe-content-identification" class="form-check" style="text-align: left;">
        <input type="checkbox" id="content-identification-checkbox" class="form-check-input">
        <label for="content-identification-checkbox" class="form-check-label">Enable PII Content
          Identification</label>
      </div>
      <div id="engine-transcribe-medical-content-identification" class="form-check hidden" style="text-align: left;">
        <input type="checkbox" id="medical-content-identification-checkbox" class="form-check-input">
        <label for="medical-content-identification-checkbox" class="form-check-label">Enable PHI Content
          Identification</label>
      </div>
      <div id="engine-transcribe-redaction" class="form-check" style="text-align: left;">
        <input type="checkbox" id="content-redaction-checkbox" class="form-check-input">
        <label for="content-redaction-checkbox" class="form-check-label">Enable PII Content Redaction</label>
      </div>
      <div id="transcribe-entity-types" class="hidden">
        <label for="transcribe-entity" class="visually-hidden">Transcribe Entities</label>
        <select id="transcribe-entity" class="form-select" style="width:100%" multiple>
          <option selected value> -- DEFAULT (ALL) -- </option>
          <option value="BANK_ROUTING">BANK ROUTING</option>
          <option value="CREDIT_DEBIT_NUMBER">CREDIT/DEBIT NUMBER</option>
          <option value="CREDIT_DEBIT_CVV">CREDIT/DEBIT CVV</option>
          <option value="CREDIT_DEBIT_EXPIRY">CREDIT/DEBIT EXPIRY</option>
          <option value="PIN">PIN</option>
          <option value="EMAIL">EMAIL</option>
          <option value="ADDRESS">ADDRESS</option>
          <option value="NAME">NAME</option>
          <option value="PHONE">PHONE</option>
          <option value="SSN">SSN</option>
        </select>
      </div>
      <button id="button-start-transcription" type="button" class="btn btn-success mx-1 mx-xl-2 my-2" title="Start"
              data-bs-toggle="button" aria-pressed="false" autocomplete="off">
        <!-- <%= require('../../node_modules/open-iconic/svg/caret-right.svg') %> -->
        <img src="assets/images/svg/caret-right.svg" />
      </button>
    </div>
  </div>

  <div class="p-2 d-none d-sm-block align-items-end" style="position:fixed;right:0;bottom:0;left:0;">
    <div class="row align-items-end">
      <div class="col">
        <!-- <div id="chime-meeting-id" class="text-muted"></div> -->
        <!-- <div id="desktop-attendee-id" class="text-muted"></div> -->
      </div>
      <div class="col">
        <div class="btn-group" role="group" style="width: 100%;">
          <button type="button" id="video-paginate-left" class="btn btn-secondary" style="display: none;">
            <!-- <%= require('../../node_modules/open-iconic/svg/caret-left.svg') %> -->
            <img src="assets/images/svg/caret-left.svg" />
          </button>
          <button type="button" id="video-paginate-right" class="btn btn-secondary" style="display: none;">
            <!-- <%= require('../../node_modules/open-iconic/svg/caret-right.svg') %> -->
            <img src="assets/images/svg/caret-right.svg" />
          </button>
        </div>
      </div>
      <div class="col d-none d-lg-block">
        <div id="video-uplink-bandwidth" class="text-muted text-end"></div>
        <div id="video-downlink-bandwidth" class="text-muted text-end"></div>
      </div>
    </div>
  </div>
  <audio id="meeting-audio" style="display:none"></audio>
  <div id="meeting-container" class="container-fluid h-100" style="display:flex; flex-flow:column">
    <div class="row mb-3 mb-lg-0" style="flex: unset;">
      <div class="col-12 col-lg-3 order-1 order-lg-1 text-center text-lg-left">
        <div id="meeting-id" class="navbar-brand text-muted m-0 m-lg-2"></div>
        <div id="mobile-chime-meeting-id" class="text-muted d-block d-sm-none" style="font-size:0.65rem;"></div>
        <div id="mobile-attendee-id" class="text-muted d-block d-sm-none mb-2" style="font-size:0.65rem;"></div>
        <div id="mobile-video-uplink-bandwidth" class="text-muted d-block d-sm-none" style="font-size:0.65rem;"></div>
        <div id="mobile-video-downlink-bandwidth" class="text-muted d-block d-sm-none mb-2" style="font-size:0.65rem;">
        </div>
      </div>
      <div class="col-8 col-lg-6 order-2 order-lg-2 text-left text-lg-center">
        <div class="btn-group mx-1 mx-xl-2 my-2" role="group" aria-label="Toggle microphone">
          <button id="button-microphone" (click)="toggleIconsMic()" type="button" class="btn btn-success" title="Toggle microphone">
            <!-- <%= require('../../node_modules/open-iconic/svg/microphone.svg') %> -->
            <img *ngIf="mic" src="assets/images/svg/microphone.svg" />
            <img *ngIf="!mic" src="assets/images/svg/microphone-off.svg" />
          </button>
          <button id="button-microphone-drop" type="button" (click)="showDropDown('dropdown-menu-microphone')" class="btn btn-success dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown"
                  aria-expanded="false" title="Select microphone"><span class="visually-hidden">Toggle Dropdown</span></button>
          <div id="dropdown-menu-microphone" (click)="showDropDown('')" class="dropdown-menu dropdown-menu-end" [ngClass]="{'show': eleId === 'dropdown-menu-microphone'}"
               aria-labelledby="button-microphone-drop" x-placement="bottom-start"
               style="position: absolute; transform: translate3d(0px, 38px, 0px); top: 0px; left: 0px; will-change: transform;">
            <a class="dropdown-item" href="#">Default microphone</a>
          </div>
        </div>
        <div class="btn-group mx-1 mx-xl-2 my-2" role="group" aria-label="Toggle camera">
          <button id="button-camera" (click)="toggleIconCam()" type="button" class="btn btn-success" title="Toggle camera">
            <!-- <%= require('../../node_modules/open-iconic/svg/video.svg') %> -->
            <img *ngIf="cam" src="assets/images/svg/video.svg" />
            <img *ngIf="!cam" src="assets/images/svg/video-off-filled.svg" />
          </button>
          <button id="button-camera-drop" type="button" (click)="showDropDown('dropdown-menu-camera')" class="btn btn-success dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown"
                  aria-expanded="false" title="Select camera"><span class="visually-hidden">Toggle Dropdown</span></button>
          <div id="dropdown-menu-camera" (click)="showDropDown('')" class="dropdown-menu dropdown-menu-end" [ngClass]="{'show': eleId === 'dropdown-menu-camera'}">
            <a class="dropdown-item" href="#">Default camera</a>
          </div>
        </div>
        <!-- <div class="btn-group mx-1 mx-xl-2 my-2" role="group" aria-label="Apply filter">
          <button id="button-video-filter" type="button" class="btn btn-success" title="Apply filter">
           
            <img src="assets/images/svg/contrast.svg" />
          </button>
          <button id="button-video-filter-drop" type="button" class="btn btn-success dropdown-toggle dropdown-toggle-split"
            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Select filter"></button>
          <div id="dropdown-menu-filter" class="dropdown-menu dropdown-menu-end"
              aria-labelledby="button-video-filter-drop" x-placement="bottom-start">
            <a class="dropdown-item" href="#">No filter</a>
          </div>
        </div> -->
        <div class="btn-group mx-1 mx-xl-2 my-2" role="group" aria-label="Toggle content share">
          <button id="button-content-share"  type="button" class="btn btn-success" title="Toggle content share">
            <!-- <%= require('../../node_modules/open-iconic/svg/camera-slr.svg') %> -->
            <img src="assets/images/svg/camera-slr.svg" />
          </button>
          <button id="button-content-share-drop" (click)="showDropDown('dropdown-menu-content-share')" type="button" class="btn btn-success dropdown-toggle dropdown-toggle-split"
                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                  title="Select content to share"></button>
          <div id="dropdown-menu-content-share" (click)="showDropDown('')" [ngClass]="{'show': eleId === 'dropdown-menu-content-share'}" class="dropdown-menu dropdown-menu-end"
                aria-labelledby="button-content-share-drop" x-placement="bottom-start"
                style="position: absolute; transform: translate3d(0px, 38px, 0px); top: 0px; left: 0px; will-change: transform;">
            <a id="dropdown-item-content-share-configuration" data-bs-toggle="modal"
            data-bs-target="#content-share-configuration-modal" class="dropdown-item" href="javascript:void(0)">Configure Content Sharing</a>
            <a id="dropdown-item-content-share-pause-resume" style="display:none" class="dropdown-item" href="javascript:void(0)">Pause Content Share</a>
            <hr class="dropdown-divider">
            <a id="dropdown-item-content-share-screen-capture" class="dropdown-item active content-share-source-option" href="javascript:void(0)">Screen Capture</a>
            <a id="dropdown-item-content-share-screen-test-video" class="dropdown-item content-share-source-option" href="javascript:void(0)">Test Video</a>
            <a id="dropdown-item-content-share-test-mono-audio-speech" class="dropdown-item content-share-source-option" href="javascript:void(0)">Test Mono
              Audio Speech</a>
            <a id="dropdown-item-content-share-test-stereo-audio-speech" class="dropdown-item content-share-source-option" href="javascript:void(0)">Test Stereo
              Audio Speech</a>
            <a id="dropdown-item-content-share-test-stereo-audio-tone" class="dropdown-item content-share-source-option" href="javascript:void(0)">Test Stereo
              Tone (L-500Hz R-1000Hz)</a>
            <label id="dropdown-item-content-share-file-item" class="dropdown-item content-share-source-option" for="content-share-item">Local file</label>
            <input id="content-share-item" type="file">
          </div>
        </div>
        <div class="btn-group mx-1 mx-xl-2 my-2" role="group" aria-label="Toggle speaker">
          <button id="button-speaker" type="button" (click)="toggleIconSpeaker()" class="btn btn-success" title="Toggle speaker">
            <!-- <%= require('../../node_modules/open-iconic/svg/volume-low.svg') %> -->
            <img *ngIf="speaker" src="assets/images/svg/volume-high.svg" />
            <img *ngIf="!speaker" src="assets/images/svg/volume-off.svg" />
          </button>
          <button id="button-speaker-drop" (click)="showDropDown('dropdown-menu-speaker')" type="button" class="btn btn-success dropdown-toggle dropdown-toggle-split"
                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Select speaker"></button>
          <div id="dropdown-menu-speaker" (click)="showDropDown('')" [ngClass]="{'show': eleId === 'dropdown-menu-speaker'}" class="dropdown-menu dropdown-menu-end"
                aria-labelledby="button-speaker-drop" x-placement="bottom-start"
                style="position: absolute; transform: translate3d(0px, 38px, 0px); top: 0px; left: 0px; will-change: transform;">
            <a class="dropdown-item" href="#">Default speaker</a>
          </div>
        </div>
        <!-- <button id="button-live-transcription" type="button" class="btn btn-success mx-1 mx-xl-2 my-2"
                title="Toggle live transcription display" data-bs-toggle="button" aria-pressed="false" autocomplete="off"
                style="display: none">
         
          <img src="assets/images/svg/code.svg" />
        </button>
        <button id="button-video-stats" type="button" class="btn btn-success mx-1 mx-xl-2 my-2"
                title="Toggle video WebRTC stats display" data-bs-toggle="button" aria-pressed="false" autocomplete="off">
         
          <img src="assets/images/svg/signal.svg" />
        </button> -->
<!--        <div class="btn-group mx-1 mx-xl-2 my-2" role="group" aria-label="Select destination">-->
<!--          <div class="btn-group" role="group">-->
<!--            <button id="button-video-recording-drop" (click)="showDropDown('dropdown-menu-recording')" type="button" class="btn btn-success dropdown-toggle"-->
<!--                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Select filter">-->
<!--              &lt;!&ndash; <%= require('../../node_modules/open-iconic/svg/cloud.svg') %> &ndash;&gt;-->
<!--              <img src="assets/images/svg/cloud.svg" />-->
<!--            </button>-->
<!--            <div id="dropdown-menu-recording" class="dropdown-menu dropdown-menu-end" (click)="showDropDown('')" [ngClass]="{'show': eleId === 'dropdown-menu-recording'}"-->
<!--                 aria-labelledby="button-video-recording-drop" x-placement="bottom-start">-->
<!--              &lt;!&ndash; <button id="button-record-self" type="button" class="btn btn-success mx-1 mx-xl-2 my-2 dropdown-item"-->
<!--                      title="Record my audio and video" data-bs-toggle="button" aria-pressed="false" autocomplete="off">-->
<!--                <svg class="svg">-->
<!--                  <circle id="rec-svg" cx="10" cy="10" r="8" fill="red"></circle>-->
<!--                </svg>-->
<!--                Capture Media to Device-->
<!--              </button> &ndash;&gt;-->
<!--              <button id="button-record-cloud" type="button" class="btn btn-success mx-1 mx-xl-2 my-2 dropdown-item"-->
<!--                      title="Capture audio and video in the cloud" data-bs-toggle="button" aria-pressed="false" autocomplete="off">-->
<!--                &lt;!&ndash; <%= require('../../node_modules/open-iconic/svg/cloud-upload.svg') %> &ndash;&gt;-->
<!--                <img src="assets/images/svg/cloud-upload.svg" />-->
<!--                Capture Media to S3-->
<!--              </button>-->
<!--              &lt;!&ndash; <button id="button-live-connector" type="button" class="btn btn-success mx-1 mx-xl-2 my-2 dropdown-item"-->
<!--                      title="Broadcast the captured artifacts in the cloud" data-bs-toggle="button" aria-pressed="false" autocomplete="off">-->
<!--                <%= require('../../node_modules/open-iconic/svg/audio.svg') %>-->
<!--                Broadcast Media to IVS-->
<!--              </button> &ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <button id="button-promote-to-primary" type="button" class="btn btn-success mx-1 mx-xl-2 my-2"
                title="Promote to or demote from Primary meeting" data-bs-toggle="button" aria-pressed="false"
                autocomplete="off">
          <!-- <%= require('../../node_modules/open-iconic/svg/badge.svg') %> -->
          <img src="assets/images/svg/badge.svg" />
        </button>
      </div>
      <div class="col-4 col-lg-3 order-3 order-lg-3 text-end text-lg-right">
        <button id="button-meeting-leave" type="button" class="btn btn-outline-success mx-1 mx-xl-2 my-2 px-4"
                title="Leave meeting">
          <!-- <%= require('../../node_modules/open-iconic/svg/account-logout.svg') %> -->
          <img src="assets/images/svg/account-logout.svg" />
        </button>
        <button id="button-meeting-end" type="button" class="btn btn-outline-danger mx-1 mx-xl-2 my-2 px-4"
                title="End meeting">
          <!-- <%= require('../../node_modules/open-iconic/svg/power-standby.svg') %> -->
          <img src="assets/images/svg/power-standby.svg" />
        </button>
      </div>
    </div>
    <div id="roster-tile-container" class="row flex-sm-grow-1 overflow-hidden h-100" style="flex: unset;">
      <div id="roster-message-container" class="d-flex flex-column col-12 col-sm-5 col-md-4 col-lg-3 h-100">
        <div class="bs-component" style="flex: 1 1 auto; overflow-y: auto; height: 50%;">
          <ul id="roster" class="list-group"></ul>
        </div>
        <div class="message d-flex flex-column pt-3" style="flex: 1 1 auto; overflow: hidden; height: 50%;">
          <div class="list-group receive-message" id="receive-message" style="flex: 1 1 auto; overflow-y: auto;
            border: 1px solid rgba(0, 0, 0, 0.125); background-color: #fff"></div>
          <div class="input-group send-message" style="display:flex;flex:0 0 auto;margin-top:0.2rem">
            <textarea class="form-control shadow-none" id="send-message" rows="1"
                      placeholder="Type a message (markdown supported)" style="display:inline-block; width:100%;
              resize:none; border-color: rgba(0, 0, 0, 0.125); outline: none; padding-left: 1.4rem"></textarea>
          </div>
        </div>
      </div>
      <div id="tile-transcript-container"
           class="d-flex flex-column col-12 col-sm-7 col-md-8 col-lg-9 my-4 my-sm-0 h-100" style="overflow-y: scroll">
        <div id="tile-area" class="v-grid"></div>
        <div id="transcript-container" class="transcript-container" style="display:none"></div>
      </div>
      <video id="content-share-video" playsinline crossOrigin="anonymous" style="display:none"></video>
    </div>
  </div>

  <div id='toast-container' aria-live="polite" aria-atomic="true">
  </div>
</div>

<!-- Content Share Configuration Modal -->

<div id="content-share-configuration-modal" class="modal fade" style="display: none">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="additional-options-modal-label">Content Share Options</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h5 class="my-2">Screen Capture</h5>
        <label for="content-capture-frame-rate" style=" text-align: left;">Frame Rate</label>
        <input type="number" id="content-capture-frame-rate" class="form-control" placeholder="30">
        <div class="form-text">
          The browser is not guaranteed to send the chosen value.
        </div>
        <h5 class="my-2">Media Processing</h5>
        <div class="form-check" style="text-align: left;">
          <input type="checkbox" id="content-enable-volume-reduction" class="form-check-input">
          <label for="content-share-voice-focus" class="form-check-label">Reduce audio volume</label>
        </div>
        <div class="form-check" style="text-align: left;">
          <input type="checkbox" id="content-enable-circular-cut" class="form-check-input">
          <label for="content-enable-circular-cut" class="form-check-label">Circular crop video</label>
        </div>
        <div id="content-simulcast-config">
          <h5 class="my-2">Simulcast Encoding</h5>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="content-enable-simulcast">
            <label class="form-check-label" for="content-enable-simulcast">Enable Simulcast</label>
          </div>
          <div class="container p-0">
            <div class="row row-cols-2 my-1">
              <div class="col-6 text-center">Low Layer</div>
              <div class="col-6 text-center">High Layer</div>
            </div>
            <div class="row row-cols-2 my-1">
              <div class="col-6">
                <input type="number" id="content-simulcast-low-max-bitratekbps" class="form-control"
                  placeholder="Max bitrate (kbps)">
              </div>
              <div class="col-6">
                <input type="number" id="content-simulcast-high-max-bitratekbps" class="form-control"
                  placeholder="Max bitrate (kbps)">
              </div>
            </div>
            <div class="row row-cols-2 my-1">
              <div class="col-6">
                <input type="number" id="content-simulcast-low-scale-factor" class="form-control"
                  placeholder="Scale factor">
              </div>
              <div class="col-6">
                <input type="number" id="content-simulcast-high-scale-factor" class="form-control"
                  placeholder="Scale factor">
              </div>
            </div>
            <div class="row row-cols-2 my-1">
              <div class="col-6">
                <input type="number" id="content-simulcast-low-max-framerate" class="form-control"
                  placeholder="Max frame rate">
              </div>
              <div class="col-6">
                <input type="number" id="content-simulcast-high-max-framerate" class="form-control"
                  placeholder="Max frame rate">
              </div>
            </div>
          </div>
        </div>
      </div>
      <button id="button-save-content-share-configs" type="button" class="btn btn-primary" data-bs-dismiss="modal"
        aria-pressed="false" autocomplete="off">
        Save
      </button>
    </div>
  </div>
</div>

<!-- Failure card if meeting cannot be joined -->

<div id="flow-failed-join" class="flow">
  <div class="container">
    <form id="form-failed-join">
      <div class="card border-warning mb-3" style="max-width: 20rem;">
        <div id="failed-join" class="card-header"></div>
        <div class="card-body">
          <h4 class="card-title">Unable to join meeting</h4>
          <p class="card-text">There was an issue joining that meeting. Check your connectivity and try again.</p>
          <small id="failed-join-error" class="text-muted"></small>
        </div>
      </div>
      <div class="d-grid gap-2">
        <button class="btn btn-lg btn-outline-warning" type="submit" (click)="back()">OK</button>
      </div>
    </form>
  </div>
</div>

