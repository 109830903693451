import {HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {URLGenerator} from '../../Utils/URLGenerator'
import {HttpConnectionService} from '../http-service/http-connection.service';
import {Router} from "@angular/router";
import { TokenStorageService } from './token-storage.service';



@Injectable({
    providedIn: 'root'
})
export class AuthService {
    urlGenerator = new URLGenerator();
    headers;
    private tokenExpirationTimer: any;

    constructor(private httpCon: HttpConnectionService, private tokenService : TokenStorageService,
                private router: Router) {
        this.headers = new HttpHeaders().set('Accept-Language', 'EN-GB').set('Content-Type', 'application/json');
    }

    signUpUser(formbody: Object) {
        return this.httpCon.postHttpReq(this.urlGenerator.getSignUpURL(), formbody, this.headers);
    }

    signInUser(formbody: Object) {
        return this.httpCon.authPostHttpReq(this.urlGenerator.getSignInURL(), formbody, this.headers);
    }

    get isLoggedIn(): boolean {
        return this.tokenService.getToken !== null ? true : false;
    }

    get companyLoggedIn(){
        return this.tokenService.loggedInCompany !== '' ? true : false;
    }

    forgetPassword(formBody: Object) {
        return this.httpCon.postHttpReq(this.urlGenerator.getForgetPasswordURL(), formBody, this.headers);
    }

    resetPassword(formBody: Object, token: string) {
        return this.httpCon.postHttpReq(this.urlGenerator.getResetPassword(token), formBody, this.headers);
    }

    confirmAccount(token: string) {
        return this.httpCon.getHttpReq(this.urlGenerator.getEmailConfirmation(token));
    }

    doLogout() {
        
        this.tokenService.removeTokens();
        localStorage.setItem('lastUrl','/');
        localStorage.removeItem('user');
        if (this.tokenExpirationTimer) {
            clearTimeout(this.tokenExpirationTimer);
        }
        this.tokenExpirationTimer = null;
        this.router.navigate(['authentication/login']);
    }

    autoLogout(expirationDateTime: any) {
        this.tokenExpirationTimer = setTimeout(() => {
            this.doLogout();
        }, expirationDateTime);
    }

    autoLogin() {
        let authToken = this.tokenService.getToken;
        let expireTime = this.tokenService.expireToken;
        let expirationDateTime = (new Date().getTime()) - (parseInt(expireTime ? expireTime : '0'));

        if (!authToken) {
            return;
        }

        if (authToken != null) {
            //this.autoLogout(expirationDateTime);
        }
    }

}
