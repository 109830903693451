import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';

import {SessionService} from './session.service';

@Injectable({
    providedIn: 'root'
})
export class LanguageHandlerService {
    lang !: string;
    langSubject = new Subject();
    translateSrv: any;
    enLangCode = 'en';
    arLangCode = 'ar';


    constructor(@Inject(DOCUMENT) private document: Document, private translateService: TranslateService, private session: SessionService
    ) {
        this.translateSrv = translateService;
    }

    loadStyle(styleName: string, lang: string) {
        this.session.cache.clear();
        this.langSubject.next(lang);
        localStorage.setItem('defLang', lang)
        this.translateService.use(lang);
        this.lang = lang;
        const head = this.document.getElementsByTagName('head')[0];

        let themeLink = this.document.getElementById('client-theme') as HTMLLinkElement;

        if (lang === this.enLangCode) {
            if (themeLink)
                themeLink.remove();
        } else {
            const style = this.document.createElement('link');
            style.id = 'client-theme';
            style.rel = 'stylesheet';
            style.href = `${styleName}`;

            head.appendChild(style);
        }

    }

}
