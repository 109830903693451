import {Injectable} from '@angular/core';
import * as Cryptojs from 'crypto-js';
import {environment} from "../../../environments/environment";

const TOKEN_KEY = 'auth-token';
const COMPANY_ID = 'company-id';
const USER_ID = 'user-id';
const REFRESH_TOKEN_KEY = 'auth-refresh-token';
const EXPIRE_TOKEN = 'auth-expire-token';
const LOGGED_IN = 'loggedUser'

@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {
    key = environment.localStorageEncKey;
    constructor() {
    }

    private encrypt(txt: string): string {
        return Cryptojs.AES.encrypt(txt, this.key).toString();
      }
    
      private decrypt(txtToDecrypt: string) {
        return Cryptojs.AES.decrypt(txtToDecrypt, this.key)
        .toString(Cryptojs.enc.Utf8);
      }

    public saveToken(accessToken: string) {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.setItem(TOKEN_KEY, accessToken);
    }

    public saveCompanyId(companyId: string) {
        localStorage.removeItem(COMPANY_ID);
        localStorage.setItem(COMPANY_ID, this.encrypt(companyId.toString()));
    }

    public saveUser(accessToken: string) {
        localStorage.removeItem(USER_ID);
        localStorage.setItem(USER_ID, accessToken);
    }

    public removeTokens(){
        localStorage.removeItem(REFRESH_TOKEN_KEY);
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(EXPIRE_TOKEN);
        localStorage.removeItem(LOGGED_IN);
        localStorage.removeItem(COMPANY_ID);
    }

    get getToken() {
        return localStorage.getItem(TOKEN_KEY);
    }

    get getUserID() {
        return this.loggedInUser;
    }

    get expireToken(){
        return localStorage.getItem(EXPIRE_TOKEN)
    }

    saveloggedInUser(user : any){
        localStorage.setItem('loggedUser', this.encrypt(user.toString()));
    }

    get loggedInUser(){
        return this.decrypt(localStorage.getItem('loggedUser') || '{}') ?? '';
    }

    get loggedInCompany(){
        return this.decrypt(localStorage.getItem('company-id') || '{}') ?? '';
    }

    public saveRefreshToken(refreshToken: string): void {
        localStorage.removeItem(REFRESH_TOKEN_KEY);
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    }

    // public getRefreshToken(): string | null {
    //     return localStorage.getItem(REFRESH_TOKEN_KEY);
    // }

    saveTimeExpire(expiresIn: number) {
        localStorage.removeItem(EXPIRE_TOKEN);
        localStorage.setItem(EXPIRE_TOKEN, String(expiresIn));
    }
}