import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import { map } from "rxjs/operators";
import { TokenStorageService } from "../services/authentication/token-storage.service";
import { RequestService } from "../services/request/request.service";
import {SessionService} from "../services/session.service";

@Injectable({
    providedIn: 'root'
})

export class AuthenticateMeetingAttendee implements CanActivate {

    constructor(private tokenStorage : TokenStorageService,private requestService: RequestService, private router: Router) {
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
       

    if(route.data.isconferrence){
        return true;
    }


    if(!this.tokenStorage?.loggedInUser){
        this.router.navigate(['404']);
        return false;
    }
    let id = this.tokenStorage.loggedInCompany? JSON.parse(this.tokenStorage.loggedInCompany): JSON.parse(this.tokenStorage.loggedInUser).userid;
    return this.requestService.validateMeetingAttendee(route.params.mid, id).pipe(
        map((data: any) => {
            if(data){
                return data;
            }else{
                this.router.navigate(['404']);
                return false;
            }

        })
    )

   
        
    }

}