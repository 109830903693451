import { Injectable } from '@angular/core';
import { URLGenerator } from "src/app/Utils/URLGenerator"; 
import { HttpConnectionService } from '../http-service/http-connection.service';

@Injectable({
  providedIn: 'root'
})
export class AWSChimeService {
  urlgen = new URLGenerator();
  constructor(private http : HttpConnectionService) {}

  creatingMeeting(){
      return this.http.getHttpReq(this.urlgen.createMeeting());
  }

  getMeetingById(mid : string){
      return this.http.getHttpReq(this.urlgen.getMeeting(mid));
  }

  async createAttendee(mid: string, uid: string){
    return await this.http.getHttpReq(this.urlgen.createAttendee(mid,uid)).toPromise();
  }
}
