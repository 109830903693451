export const constants = {
    ResetPasswordProcessID: "10",
    UserEmailConfirmationProcessID: "11",
    changePasswordString: "User must change the password.",
    irWidgetUri: "https://ir-pro-ibe.mubasherdigitalconnect.com/irprowidget/ir/irprowidget",
    searchConstants: {
        lang: 'en',
        hostName: 'http://data.feedgma.com/mix2/ClientServiceProvider',
        assets: '1,5,6,7,8,9,10',
        supportedExchanges: 'ADSM,AMEX,ASE,BSE,CASE,CSE,DFM,DIFX,DJI,DSM,ISE,ISX,KSE,LBBSE,LSE,LSM,MSM,NSDQ,NYSE,PSE,SYDSE,TDWL,TNTSE,FIGS,DFNMF,PFX,GTISEP,GTISPM,DFNMM,FTSE,S&P,SDKSE,ITSE',
        searchFields: 'S,SDES,DES,ISINC,COM',
        includeFields: 'DES,ISINC,DEP,CFID,TC,DS,CTD,COM,CC,LIS,CCV,TCC,MC'
    },

    backendConstants: {
        hostUrl: 'http://localhost',
        port: '8080',
        authenticationConstants: {
            controller: '/user',
            signUpMethod: '/signup',
            confirmAccount: '/confirm-account',
            signinMethod: '/signin',
            resetPassword: '/reset-password',
            forgetPassword: '/forgot-password',

        }
    },

    companyService: {
        getCompaniesApiUrl: '/companies-all',
        searchCompanyApiUrl: '/search-company',
        companySectors: '/sectors',
        companyExchange: '/exchanges',
        localMockDataURL: 'assets/mock-data/company-profile.json',
        cpManagement: '/company-management',
        companyAuditors: '/company-auditors',
        getNewsAnnoucements: '/news-ann',
        companyListingStatus: '/company-listing-status',
        companyInvs: '/company-invs',
        membersPicUrls: '/individuals/',
        getIrProNewsAnnoucements: '/get-info',
        createIrProNewsAnn: '/create-info',
        updateIrProNewsAnn: '/update-info',
        deleteIrProNewsAnn: '/delete-info',
        getAllInvestor: '/user/all-investor-profile',
        searchInvestor: '/user/search-investor',
        getSubscribedInvestors: '/get-subscribed-investor',
        getSubscribedInvestorsDto: '/user/get-subscribed-investor-details',
        getCompanyWidgets: '/get-company-widgets'


    },

    investorService: {
        subscription: '/investor/subscribe',
        checkSubs: '/investor/check-sub',
        unsubCompany: '/investor/unsubCompany',
        getSubCompanies: '/investor/getSubCompanies',
        getProfile: '/user/get-profile/',
        editProfile: '/user/edit-profile',
        getNewsAnnoucements: '/investor/news-ann',
        getNewsAnnoucementsSearch: '/investor/search-news-ann',
        initiateRequest: '/request/submit-request',
        getMyRequest: '/request/get-requests',
        getOtherRequest: '/request/get-other-requests',
        editRequest: '/request/update-request',
        responedRequest: '/request/respond-request',
        deleteRequest: '/request/delete-request',
        filterRequest: '/request/filter-request',
        getEvents: '/investor/events',
        removeDoc: '/request/remove-doc'
    },

    companyIcon: '/company/logo/',
    validateMeetingAttendees: '/request/validate',
    newsAnnouncementsImg: '/platfromattachments/newsAnnouncements/',
    companyCountryFlag: '/country/',
    countryList : '/country-list',
    contactUs: '/contact-us/save',
    createVirtualConference: '/create-conference',
    updateVirtualConference: '/update-conference',
    getVirtualConference: '/get-conferences',
    deleteVirtualConference: '/delete-conference',
    getInfoDetails: '/investor/news-ann-details',
    citiesList : '/cities-list/',
    investorType : 4,
    getCityByCode: '/city',
    downloadDoc: '/request/doc',
    getCountryByCode: '/country',
    insInvestorType: 3,
    companyUserType: 1,
    companyUserSuperType: 2,
    requestforInformation: 1,
    meeting: 2,
    securedShareFile: 3,

    requestType: [
        {
            id: 1,
            name: "Request for Information",
            arabicname:"طلب للحصول على معلومات",
        },
        {
            id: 2,
            name: "1-2-1 Meeting",
            arabicname:"1-2-1 الاجتماع",
        },

        {
            id: 3,
            name: "Secured Share File",
            arabicname:"ملف المشاركة الآمن"
        },
    ],

    newsType : {
        investorNews : 1,
        companyNews : 2,
        irproNews : 3,
    },

    utterances : [
        ["What is MUBASHER Digital Connect / IR Pro"],
        ["Why MUBASHER Digital Connect / IR Pro"],
        ["What are the features of MUBASHER Digital Connect / IR Proo"],
        ["What are the features which digitally connects investors with the companies","features","digitally connect"],
        ["What is mubasher group","mubasher group"],
        ["Who are the main users of MUBASHER Digital Connect / IR Pro","users"],
        ["What are the main types of investor users in the system","investor users"],
        ["What is investor relations domain","investor relations"],
        ["How does request for information feature works","request for information"],
        ["How does 1-2-1 feature work in MUBASHER Digital Connect / IR Pro","121 meetings"],
        ["How does secured file share work in MUBASHER Digital Connect / IR Pro","secured file share",],
        ["How to contact MUBASHER Digital Connect / IR Pro team","contact", "team"],
        ["How to register with MUBASHER Digital Connect / IR Pro","register"],
        ["How to search companies in MUBASHER Digital Connect / IR Pro", "search companies"],
        ["How to search investors in MUBASHER Digital Connect / IR Pro","search", "investors"],
        ["Are company news available in MUBASHER Digital Connect / IR Pro platform","news"],
        ["Are company announcements available in MUBASHER Digital Connect / IR Pro platform","announcements"],
        ["What is the source of company news and announcements in MUBASHER Digital Connect / IR Pro platform","source", "news and announcements"],
        ["Is watchlist available MUBASHER Digital Connect / IR Pro platform","watchlist "],
        ["What is the technology used in secured file share feature","secured file share", "technology"],
        ["How to rest my password","reset password"],
        ["What kind of information can i find in MUBASHER Digital Connect / IR Pro related to listed companies","information", "listed companies"],
        ["What is the methodology of normalizing the financials in MUBASHER Digital Connect / IR Pro","financials"],
        ["What is the nature of the trading data in the platform","trading data"],
        ["Can i join MUBASHER Digital Connect / IR Pro as an individual investor","individual investor"],
        ["Who are the institutional investors registered in the system","institutional investors"],
        ["What are the markets covered in MUBASHER Digital Connect / IR Pro", "markets"],
        ["How can i delete my account in MUBASHER Digital Connect / IR Pro","delete"],
        ["What is the purpose of MUBASHER Digital Connect / IR Pro", "purpose"],
        ["How can i join with virtual conferences in MUBASHER Digital Connect / IR Pro", "virtual conferences"],
        ["Can the virtual conferences be recorded", "virtual conference", "record"]
    ],

    answers : [
        
        ["MUBASHER Digital Connect / IR Pro is a platform connecting both individual and institutional investors with the listed companies"],
        ["MUBASHER Digital Connect / IR Pro can help you connect with the listed companies directly to explore investment opportunities"],
        ["MUBASHER Digital Connect / IR Pro provides dashboards, smart searches and features to digitally connect investors with the companies"],
        ["MUBASHER Digital Connect / IR Pro digitally connects investors with listed companies using Request for Information, 1-2-1 Meetings and Secured File Share features"],
        ["Mubasher group consists of MENA region focused fintech companies providing online trading solutions, investor relations website and mobile solutions, data feeds solutions and is considered as the largest group focused in the fintech in MENA region"],
        ["The main users of MUBASHER Digital Connect / IR Pro are Investor Users and Company Users"],
        ["MUBASHER Digital Connect / IR Pro lets individual and institutional users to register with the system"],
        ["Investor Relations is the domain of connecting the stakeholders of listed companies with the potential and existing investors. MUBASHER Digital Connect / IR Pro is dedicated for enabling it"],
        ["Investors and companies can initiate ‘Request for Information’ to connect directly and clarify any concerns of their preference"],
        ["MUBASHER Digital Connect / IR Pro provides the companies and investors to connect in directly using e-meetings"],
        ["MUBASHER Digital Connect / IR Pro enables the users to share confidential documents secured manner between the source and the destination"],
        ["You can get in touch with MUBASHER Digital Connect / IR Pro team from irsupport@globalfinancialmedia.com"],
        ["You can register with MUBASHER Digital Connect / IR Pro by clicking ‘Sign Up’ button "],
        ["You can search companies using ‘Find Companies’ tab"],
        ["You can search companies using ‘Find Investors’ tab"],
        ["Yes, Company News are available in the MUBASHER Digital Connect / IR Pro platform"],
        ["Yes. Company Announcements are available in MUBASHER Digital Connect / IR Pro platform"],
        ["The sources are exchange and news & announcements contributed by the company users registered with the IR Pro platform"],
        ["Yes, company watchlist is available for the investor users to check the prices"],
        ["Blockchain technology is used in secured file share feature"],
        ["You can rest the password using ‘Forgot Password’ feature"],
        ["You can find all the information related to the IR domain including company’s trading information, historical performance, company profile data, financials, company master data etc."],
        ["The financials are normalized as per the Mubasher’s internal standards aligning with the global standard of financials publications"],
        ["The trading data are 15 minutes delayed streaming in the platform"],
        ["Yes, you can sign up with the system as an individual investor"],
        ["Institutional investors are the company investors with the intention of connecting and doing investments in listed companies"],
        ["The markets DFM, ADX, KSE, BSE, QE, TDWL (KSA),ASE,EGX, CSE (Casablanca), MSM are covered in MUBASHER Digital Connect / IR Pro"],
        ["Please get in touch with IR Pro team to assist with your query. "],
        ["The purpose of MUBASHER Digital Connect / IR Pro is to digitally connect the investors and companies using smart features"],
        ["You may select the upcoming virtual conference and connect with it"],
        ["Yes virtual conferences can be recorded"],
        ["Haha!", "Good one!"],
        
        
    ],
    alternatives : [
        "Go on...",
        "Try again",
    ],

    AwsChime : {
        createMeeting: '/createMeetings',
        getMeeting: '/getMeetings',
        createAttendee: "/createAttendee"
    }


}