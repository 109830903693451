import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

export interface AuthResponse {
    accessToken: string;
    emailId: string;
    expiresIn: number;
    idToken: string;
    refreshToken: string;
    tokenType: string;
    userType: UserType;
}

export interface UserType {
    id: number;
    title: string;
}

@Injectable({
    providedIn: 'root'
})
export class HttpConnectionService {

    constructor(private http: HttpClient) {
    }

    postHttpReq(url: string, body: Object, headers ?: HttpHeaders) {
        return this.http.post(url, body, {headers: headers})
    }

    authPostHttpReq(url: string, body: Object, headers: HttpHeaders) {
        return this.http.post<AuthResponse>(url, body, {headers: headers})
    }

    getHttpReq(url: string, headers ?: any) {
        return this.http.get(url, {headers});
    }

    deleteHttpReq(url: string, body?: Object, headers ?: HttpHeaders){
        return this.http.delete(url, {headers: headers,body: body})
    }

    putHttpReq(url: string, body: Object) {
        return this.http.put(url, body)
    }

}
