import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {AuthenticateMeetingAttendee} from './guards/authenticateMeetingAttendee';
import {CompanyProfileFilterPipe} from './pipes/company-profile-filter.pipe';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthInterceptor} from '../app/Interceptors/auth.interceptor'
import {ModalModule} from "ngx-bootstrap/modal";
import { CacheResponseService } from './Interceptors/cache-response.service';

@NgModule({
    declarations: [
        AppComponent,
        CompanyProfileFilterPipe,

    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ModalModule.forRoot(),
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot(
            {
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient]
                }
            }
        )

    ],
    providers: [AuthenticateMeetingAttendee, CompanyProfileFilterPipe,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: CacheResponseService,
        multi: true
    }],
    bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http)
}
