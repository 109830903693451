import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {CompanyProfile} from '../Models/company-data-models/company-profile';
import { InvestorProfile } from '../Models/investor/investor-profile';

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    public _tabActivated = new Subject<number>();
    public companyProfiles = new Subject<CompanyProfile[]>();
    public sessionToken: any;
    public profile = new Subject<any>();
    selectedCP !: CompanyProfile;
    public cache : Map<any, any> = new Map();
    reload = true;
    investorProfile!: InvestorProfile;
    currentUser: any = {};
    meetingID : string;
    //public isAuthenticated: boolean = false;
    fromDateSelected: Date;

    constructor() {
    }

    removeCachedData(key: any){
        this.cache.forEach((val, _key) => {
            if(_key.toString().includes(key)) this.cache.delete(_key);  
        })
    }

    fireTabActivatedSubject(val: number) {
        this._tabActivated.next(val);
    }

    toDayDate(){
        let _date = new Date();
        let _month = _date.getMonth() +1;
        let _day = _date.getDate() < 10 ? '0'+_date.getDate() :  _date.getDate() ;
        return _date.getFullYear() + "-" + _month + "-"+_day;
    }

    sendDateFunction(event: any) {
        if (event){
            this.fromDateSelected = event.target.value;
        }
    }
}
