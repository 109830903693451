import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class CacheResponseService implements HttpInterceptor{
  
  constructor(private session : SessionService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(req.method !== 'GET'){
      return next.handle(req)
    }
    const cachedResponse = this.session.cache.get(req.url);
    if(cachedResponse){
      return of(cachedResponse);
    }else{
      return next.handle(req).pipe(
        tap(stateEvent => {
          if(stateEvent instanceof HttpResponse) {
              this.session.cache.set(req.url, stateEvent)
          }
      })
      )
    } 

  }
}
