import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import langFilear from "../assets/i18n/ar.json";
import {TranslateService} from '@ngx-translate/core';
import {LanguageHandlerService} from './services/language-handler.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionService} from './services/session.service';
import {constants} from './Utils/App-Constants';
import {AuthService} from "./services/authentication/auth.service";
import * as $ from 'jquery'


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    //styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'MUBASHER Digital Connect / IR Pro';

    constructor(private langHandler: LanguageHandlerService, private langTranslator: TranslateService,
                private route: ActivatedRoute,
                private router: Router,
                private authService: AuthService,
                private session: SessionService) {

        let storedLang = localStorage.getItem('defLang');
        let defLang = '';
        if (storedLang == null) {
            defLang = this.langHandler.enLangCode;
            localStorage.setItem('defLang', defLang);
        } else {
            defLang = storedLang ?? this.langHandler.enLangCode;
        }


        this.langTranslator.setDefaultLang(defLang);
        this.langTranslator.setTranslation(defLang, langFilear);
        this.langHandler.loadStyle(`lang-${defLang}.css`, defLang);

        this.isResetPassword();
    }

    ngOnInit() {
        this.chatBotInit();
        this.authService.autoLogin();
    }


    isResetPassword() {

        this.route.queryParams.subscribe(
            params => {
                if (params.req != undefined) {
                    this.session.sessionToken = params.token;

                    switch (params.req) {
                        case constants.ResetPasswordProcessID:
                            this.router.navigate(['authentication/login/password-reset']);
                            break;
                        case constants.UserEmailConfirmationProcessID:
                            this.router.navigate(['authentication/login/req-success', {pId: constants.UserEmailConfirmationProcessID}]);
                            break;
                        default:
                            break;
                    }
                }
            }
        )
    }
    
    chatBotInit(){

        $(".chatBot #formChat").submit((e) => {
            e.preventDefault();
            if ($(".chatBot #input").val() != (undefined || '')) {
                $(".chatBot .sendOptionMessage").remove();
                let value = $(".chatBot #input").val();
                $(".chatBot #input").val("");
                this.output(value);
            }
        });
        
        for (let i = 0; i < 2; i++) {
            this.addOptionsToChatBot();
        }
    
        $('.chatBot .openChatBot').on('click', function() {
            $('.chatBot').toggleClass('chatBotShow');
            $('.chatBot').toggleClass('chatBotStart');
        });
    
        $('.chatBot .closeHide').on('click', function() {
            $('.chatBot').toggleClass('chatBotShow');
            $('.chatBot').toggleClass('chatBotStart');
        });
    
        // $('.chatBot .startChatButton').on('click', function() {
        //     $('.chatBot').toggleClass('chatBotStart');
        // });
    
        $('.chatBot .closeData').on('click', () => {
            if ($(".chatBot").hasClass("chatBotStart")) {
                $('.chatBot').toggleClass('chatBotStart');
            }
            $('.chatBot').toggleClass('chatBotShow');
            $(".chatBot .sendOptionMessage").remove();
            $(".chatBot .messages .response").remove();
            for (let i = 0; i < 2; i++) {
                this.addOptionsToChatBot();
            }
        });
        
        
        
        
        
        
    }

    output(input: any) {
        let product;
        let text = input.toLowerCase().replace(/[^\w\s\d]/gi, "");
        text = text
            .replace(/ a /g, " ")
            .replace(/whats/g, "what is")
            .replace(/please /g, "")
            .replace(/ please/g, "")
            .replace(/r u/g, "are you");
    
        if (this.compare(constants.utterances, constants.answers, text)) {
            // Search for exact match in triggers
            product = this.compare(constants.utterances, constants.answers, text);
        } else {
            product = constants.alternatives[Math.floor(Math.random() * constants.alternatives.length)];
        }
    
        this.addChatEntry(input, product);
    }

    compare(utterancesArray: any, answersArray: any, str: any) {
        let reply;
        let replyFound = false;
        for (let x = 0; x < utterancesArray.length; x++) {
            for (let y = 0; y < utterancesArray[x].length; y++) {
                if (utterancesArray[x][y] === str) {
                    let replies = answersArray[x];
                    reply = replies[Math.floor(Math.random() * replies.length)];
                    replyFound = true;
                    break;
                }
            }
            if (replyFound) {
                break;
            }
        }
        return reply;
    }

    addChatEntry(input: any, product: any) {
        let messagesContainer = document.getElementById("messages");
        let userDiv = document.createElement("div");
        userDiv.id = "user";
        userDiv.className = "user response";
        userDiv.innerHTML = `<div class="avatar"><i class="far fa-user"></i></div> <p class="message">${input}</p>`;
        messagesContainer?.appendChild(userDiv);
    
        let botDiv = document.createElement("div");
        let botText = document.createElement("p");
        botText.className = "message";
        botText.innerText = "Typing...";
        botDiv.id = "bot";
        botDiv.className = "bot response";
        botDiv.innerHTML = `<div class="avatar"><img src="assets/images/icons/chatbot.png" alt="bot image"></div>`;
        botDiv.appendChild(botText);
    
        messagesContainer?.appendChild(botDiv);
    
        $(".chatBot .boxChat").scrollTop($(".chatBot .boxChat")[0].scrollHeight);
    
        setTimeout(() => {
            botText.innerText = product;
            $(".chatBot .sendOptionMessage").remove();
            for (let i = 0; i < 2; i++) {
                this.addOptionsToChatBot();
            }
            $(".chatBot .boxChat").scrollTop($(".chatBot .boxChat")[0].scrollHeight);
        }, 1000);
    }

    addOptionsToChatBot() {
        let someNumber = Math.floor(Math.random() * constants.utterances.length);
        let optionNumber = Math.floor(Math.random() * constants.utterances[someNumber].length);
    
        let optionButton = document.createElement("button");
        optionButton.className = "sendOptionMessage";
        optionButton.innerText = constants.utterances[someNumber][optionNumber];
        optionButton.onclick = function() {
            $('.chatBot #input').val($(this).text());
            $('.chatBot .send').click();
            $(".chatBot .sendOptionMessage").remove();
        };
        $('.chatBot .options').append(optionButton);
    }

}
