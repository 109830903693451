import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {URLGenerator} from "../../Utils/URLGenerator";
import {HttpConnectionService} from "../http-service/http-connection.service";
import {LanguageHandlerService} from "../language-handler.service";
import {TokenStorageService} from "../authentication/token-storage.service";
import {InitiateRequest, ReqDocument} from "../../Models/request/Initiate-request";
import {BehaviorSubject, Observable, of} from "rxjs";
import {tap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class RequestService {

    data: any = {};

    public myRequestDataResponse: BehaviorSubject<any> = new BehaviorSubject<any>(this.data);
    public otherRequestDataResponse: BehaviorSubject<any> = new BehaviorSubject<any>(this.data);

    headers: HttpHeaders;
    urlGenerator = new URLGenerator();

    constructor(private httpCon: HttpConnectionService,
                private langHandler: LanguageHandlerService,
                public tokenService: TokenStorageService,
                private http: HttpClient) {
        this.headers = new HttpHeaders().set('Accept-Language', 'EN-GB').set('Content-Type', 'application/json');

    }

    initiateRequest(formdata: InitiateRequest) {
        return this.httpCon.postHttpReq(this.urlGenerator.initiateRequest(), formdata);
    }

    fetchAllRequest(userId: number, pgs: number): Observable<any> {
        if (this.myRequestDataResponse?.getValue()) {
            return of(this.myRequestDataResponse?.getValue());
        }
        return this.httpCon.getHttpReq(this.urlGenerator.getMyRequestAgainstId(userId, pgs))
            .pipe(tap(data => {
                this.myRequestDataResponse.next(data)
            }));
    }

    fetchOtherRequest(userId: number, pgs: any): Observable<any> {
        if (this.otherRequestDataResponse?.getValue()) {
            return of(this.otherRequestDataResponse?.getValue());
        }
        return this.httpCon.getHttpReq(this.urlGenerator.getOtherRequestAgainstId(userId, pgs))
            .pipe(tap(data => {
                this.otherRequestDataResponse.next(data)
            }));
    }

    editRequest(editReqObj: any) {
        return this.httpCon.putHttpReq(this.urlGenerator.editRequest(), editReqObj);
    }

    responseRequest(editReqObj: any) {
        return this.httpCon.postHttpReq(this.urlGenerator.responsedRequest(), editReqObj);
    }

    deleteRequest(requestId: number | undefined) {
        return this.httpCon.deleteHttpReq(this.urlGenerator.deleteRequest(requestId), {}, this.headers);
    }

    removeDocument(files: any) {
        return this.httpCon.deleteHttpReq(this.urlGenerator.deleteDocumentReq(), files, this.headers);
    }

    searchRequest(searchReq: any) {
        return this.httpCon.getHttpReq(this.urlGenerator.searchRequest(searchReq));
    }

    downloadDocument(searchReq: any) {
        return this.httpCon.getHttpReq(this.urlGenerator.searchRequest(searchReq));
    }

    downloadReqDocumentReq(docKey: string) {
        window.location.href = this.urlGenerator.downloadReqDocumentReq(docKey);
    }

    validateMeetingAttendee(mid: number, uid: number){
        return this.http.get(this.urlGenerator.validateMeetingAttendee(mid,uid));
    }

    getDocumentTypes(imageType: string) {
        return this.httpCon.getHttpReq(this.urlGenerator.downloadReqDocumentReq(imageType));
    }
}
